<template>
  <div>
    <van-tabs v-model="active" @change="switchTab">
      <van-tab>
        <template #title>
          <span :class="active===0?'dd':''">全部记录</span>
        </template>
        <van-empty v-if="!show" description="暂无审核单" />
        <div class="content">
          <van-list
            :immediate-check="false"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="box" v-for="item in data" :key="item.id">
              <div class="top">申请认证时间：{{item.authApplyTime}}</div>
              <div class="bottom">
                <div class="b-left">
                  <img :src="item.licenseImg" alt />
                  <div @click="setimg(item.licenseImg)">点击查看</div>
                </div>
                <div class="b-center">
                  <div>
                    姓名：
                    <span>{{item.username}}</span>
                  </div>
                  <div>
                    电话：
                    <span>{{item.phone}}</span>
                  </div>
                  <div>
                    法人：
                    <span>{{item.representName}}</span>
                  </div>
                  <div>
                    公司：
                    <span>{{item.companyName}}</span>
                  </div>
                  <div>
                    法人身份证：
                    <span>{{item.representNumber}}</span>
                  </div>
                </div>
                <div class="b-right">
                  <div v-if="item.flag">
                    <div @click="open(item.id,1)">审核通过</div>
                    <div @click="open(item.id,0)" class="err">审核驳回</div>
                  </div>
                  <span v-else :class="item.cls">{{item.text}}</span>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <span :class="active===1?'dd':''">待审核</span>
        </template>
        <van-empty v-if="!show1" description="暂无审核单" />
        <div class="content">
          <van-list
            :immediate-check="false"
            v-model="loading1"
            :finished="finished1"
            finished-text="没有更多了"
            @load="onLoad1"
          >
            <div class="box" v-for="item in data" :key="item.id">
              <div class="top">申请认证时间：{{item.authApplyTime}}</div>
              <div class="bottom">
                <div class="b-left">
                  <img :src="item.licenseImg" alt />
                  <div @click="setimg(item.licenseImg)">点击查看</div>
                </div>
                <div class="b-center">
                  <div>
                    姓名：
                    <span>{{item.username}}</span>
                  </div>
                  <div>
                    电话：
                    <span>{{item.phone}}</span>
                  </div>
                  <div>
                    法人：
                    <span>{{item.representName}}</span>
                  </div>
                  <div>
                    公司：
                    <span>{{item.companyName}}</span>
                  </div>
                  <div>
                    法人身份证：
                    <span>{{item.representNumber}}</span>
                  </div>
                </div>
                <div class="b-right">
                  <div v-if="item.flag">
                    <div @click="open(item.id,1)">审核通过</div>
                    <div @click="open(item.id,0)" class="err">审核驳回</div>
                  </div>
                  <span :class="item.cls" v-else>{{item.text}}</span>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <span :class="active===2?'dd':''">已审核</span>
        </template>
        <van-empty v-if="!show2" description="暂无审核单" />
        <div class="content">
          <van-list
            :immediate-check="false"
            v-model="loading2"
            :finished="finished2"
            finished-text="没有更多了"
            @load="onLoad2"
          >
            <div class="box" v-for="item in data" :key="item.id">
              <div class="top">申请认证时间：{{item.authApplyTime}}</div>
              <div class="bottom">
                <div class="b-left">
                  <img :src="item.licenseImg" alt />
                  <div @click="setimg(item.licenseImg)">点击查看</div>
                </div>
                <div class="b-center">
                  <div>
                    姓名：
                    <span>{{item.username}}</span>
                  </div>
                  <div>
                    电话：
                    <span>{{item.phone}}</span>
                  </div>
                  <div>
                    法人：
                    <span>{{item.representName}}</span>
                  </div>
                  <div>
                    公司：
                    <span>{{item.companyName}}</span>
                  </div>
                  <div>
                    法人身份证：
                    <span>{{item.representNumber}}</span>
                  </div>
                </div>
                <div class="b-right">
                  <div v-if="item.flag">
                    <div @click="open(item.id,1)">审核通过</div>
                    <div @click="open(item.id,0)" class="err">审核驳回</div>
                  </div>
                  <span :class="item.cls" v-else>{{item.text}}</span>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <van-overlay :show="flag1" @click="flag1 = false">
      <div class="wrapper">
        <img :src="imgurl" alt />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { getReviewPage, reviewAuth } from '@/api/user'
export default {
  data() {
    return {
      imgurl: '',
      flag1: false,
      loading: false,
      finished: false,
      loading1: false,
      finished1: false,
      loading2: false,
      finished2: false,
      num: 1,
      num1: 1,
      num2: 1,
      data: [],
      active: 0,
      show: true,
      show1: true,
      show2: true,
      isReviewer: 0,
      flag: true,
      text: ''
    }
  },
  created() {
    this.isReviewer = this.$route.query.isReviewer
    this.getReviewPage()
  },
  methods: {
    setimg(row) {
      this.flag1 = true
      this.imgurl = row
    },
    async onLoad() {
      this.num += 1
      console.log('触底', this.num)
      const { data } = await getReviewPage({
        pageSize: 10,
        pageNum: this.num
      })
      if (data.data.records.length === 0) {
        this.finished = true
      } else {
        this.data = [...this.data, ...data.data.records]
        this.loading = false
      }
    },
    async onLoad1() {
      this.num1 += 1
      console.log('触底', this.num1)
      const { data } = await getReviewPage({
        pageSize: 10,
        pageNum: this.num1,
        authStatus: 1
      })
      if (data.data.records.length === 0) {
        this.finished1 = true
      } else {
        this.data = [...this.data, ...data.data.records]
        this.loading1 = false
      }
    },
    async onLoad2() {
      this.num2 += 1
      console.log('触底', this.num2)
      const { data } = await getReviewPage({
        pageSize: 10,
        pageNum: this.num2,
        authStatus: 2
      })
      if (data.data.records.length === 0) {
        this.finished2 = true
      } else {
        this.data = [...this.data, ...data.data.records]
        this.loading2 = false
      }
    },
    open(id, a) {
      let message = ''
      a === 0 ? (message = '是否确认驳回审核') : (message = '是否确认通过审核?')
      Dialog.confirm({
        message: message
      })
        .then(async () => {
          const { data } = await reviewAuth({
            authStatus: a,
            id: id
          })
          console.log(data)
          if (data.code === 200) {
            Toast.success('已处理')
            this.num = 1
            this.num1 = 1
            this.num2 = 1
            this.data = []
            this.getReviewPage(this.active)
          } else {
            Toast.fail(data.msg)
          }
        })
        .catch(() => {})
    },
    switchTab(a) {
      this.show = true
      this.show2 = true
      this.show1 = true
      this.num = 1
      this.num1 = 1
      this.num2 = 1
      this.data = []
      this.getReviewPage(a)
    },
    async getReviewPage(a = 0) {
      const { data } = await getReviewPage({
        pageSize: 10,
        pageNum: this.num,
        authStatus: a
      })
      console.log(data.data.records)
      this.data = data.data.records
      this.data.forEach(res => {
        if (res.authStatus === 4) {
          res.flag = false
          res.text = '已驳回'
          res.cls = 'black'
        } else if (res.authStatus === 3) {
          res.flag = false
          res.text = '审核通过'
          res.cls = 'orange'
        } else if (res.authStatus === 2) {
          if (this.isReviewer === '1') {
            res.flag = false
            res.text = '等待复审'
            res.cls = 'blue'
            console.log(11111)
          } else {
            console.log(22222)
            res.flag = true
          }
        } else {
          res.flag = true
        }
      })
      if (this.data.length === 0) {
        this.show = false
        this.show1 = false
        this.show2 = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-overlay {
  z-index: 3;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    width: 90vw;
  }
}
.black {
  color: #999999;
}
.orange {
  color: #f8892f;
}
.blue {
  color: #2f92f8;
}
/deep/ .van-tabs__content {
  padding-bottom: 65px;
  padding-top: 44px;
}
/deep/ .dd {
  font-size: 14px !important;
  color: #f8892f;
}
/deep/ .van-tabs__line {
  background-color: #f8892f;
  width: 30px;
}
/deep/ .van-tabs__wrap {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 2;
}
.content {
  padding-top: 15px;
  box-sizing: border-box;
  width: 100%;
  .box {
    box-shadow: 0 0 4px #cccccc;
    margin-bottom: 15px;
    position: relative;
    width: 94vw;
    margin-left: 3%;
    background-color: #fff;
    height: 150px;
    border-radius: 10px;
    padding: 8px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    color: #333;
    .top {
      font-size: 14px;
    }
    .bottom {
      display: flex;
      align-items: center;
      .b-left {
        height: 100px;
        width: 95px;
        border-radius: 7px;
        border: 1px solid #dcdcdc;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
        overflow: hidden;
        img {
          width: 95px;
          height: 73px;
        }
        div {
          border: 1px solid #f8892f;
          text-align: center;
          padding: 1px 5px;
          height: 18px;
          color: #f8892f;
          line-height: 18px;
          border-radius: 4px;
        }
      }
      .b-center {
        span {
          color: #999999;
        }
        > div {
          margin: 4px 0;
        }
      }
      .b-right {
        font-size: 14px;
        position: absolute;
        right: 15px;
        top: 23%;
        > div div {
          border: 1px solid #2f92f8;
          color: #2f92f8;
          border-radius: 4px;
          padding: 2px 3px;
          margin-bottom: 8px;
          border: none;
          outline: none;
        }
        .err {
          color: #ff0000;
          border: 1px solid #ff0000;
        }
      }
    }
  }
}
</style>
